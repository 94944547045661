<template>

  <!-- profile info  -->
  <section id="tambah-pengajuan-master-layanan">
    <b-card
      header="Tambah Master Layanan"
      header-tag="h4"
      header-class="border-bottom mb-2"
    >
      <template v-if="alert">
        <b-alert
          :variant="alertDetail.variant"
          show
        >
          <h4 class="alert-heading">
            {{ alertDetail.title }}
          </h4>
          <div
            v-if="alertDetail.variant === 'danger' && alertDetail.errors !== ''"
            class="alert-body"
          >
            <ul v-if="typeof alertDetail.errors === 'object'">
              <li
                v-for="(item, index) in alertDetail.errors"
                :key="index"
              >
                {{ index }}: {{ item }}
              </li>
            </ul>
            <span v-else>{{ alertDetail.text + ': ' + alertDetail.errors }}</span>
          </div>
          <div
            v-else
            class="alert-body"
          >
            {{ alertDetail.text }}
          </div>
        </b-alert>
      </template>

      <validation-observer ref="formBisnis">
        <form
          id="editMasterBisnis"
          @submit.prevent="submitForm"
        >

          <b-row>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
                label="Domain Layanan"
                label-for="vi-service_domain_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Domain Layanan"
                  rules="required"
                >
                  <v-select
                    id="vi-service_domain_id"
                    v-model="service_domain_id"
                    required
                    name="service_domain_id"
                    :options="referenceData.ref_domain_layanan"
                    placeholder="Domain Layanan"
                    label="nama"
                    @input="getAreaLayanan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
                label="Area Layanan"
                label-for="vi-service_area_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Area Layanan"
                  rules="required"
                >
                  <v-select
                    id="vi-service_area_id"
                    v-model="service_area_id"
                    required
                    name="service_area_id"
                    :options="daftarAreaLayanan"
                    placeholder="Area Layanan"
                    label="nama"
                    @input="getKatLayanan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
                label="Kategori Layanan"
                label-for="vi-service_category"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Kategori Layanan"
                  rules="required"
                >
                  <v-select
                    v-if="!fungsi_and_sub"
                    id="vi-service_category"
                    v-model="service_category"
                    required
                    name="service_category"
                    :options="daftarKatLayanan"
                    placeholder="Kategori Layanan"
                    label="nama"
                    @input="getSubKatLayanan"
                  />
                  <b-input-group
                    v-if="fungsi_and_sub"
                  >
                    <b-form-input
                      id="vi-service_category"
                      v-model="kategori_layanan_nama"
                      required
                      name="service_category"
                      placeholder="Kategori Layanan"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-secondary"
                        @click="unitReset('cat')"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          size="12"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
                label="Sub Kategori Layanan"
                label-for="vi-service_sub_category"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Sub Kategori Layanan"
                  rules="required"
                >
                  <v-select
                    v-if="!sub_fungsi"
                    id="vi-service_sub_category"
                    v-model="service_sub_category"
                    required
                    name="service_sub_category"
                    :options="daftarSubKatLayanan"
                    placeholder="Sub Kategori Layanan"
                    label="nama"
                    @input="setSubKatLayanan"
                  />
                  <b-input-group
                    v-if="sub_fungsi"
                  >
                    <b-form-input
                      id="vi-service_sub_category"
                      v-model="sub_kategori_layanan_nama"
                      required
                      name="service_sub_category"
                      placeholder="Sub Kategori Layanan"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-secondary"
                        @click="unitReset('sub')"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          size="12"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan nama dari layanan yang dihasilkan oleh unit kerja/perangkat daerah.'"
                label="Nama Layanan"
                label-for="vi-nama_layanan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Layanan"
                  rules="required"
                >
                  <b-form-input
                    id="vi-nama_layanan"
                    v-model="nama_layanan"
                    required
                    name="nama_layanan"
                    placeholder="Nama Layanan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan penjelasan tujuan dari layanan.'"
                label="Tujuan Layanan"
                label-for="vi-tujuan_layanan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tujuan Layanan"
                  rules="required"
                >
                  <b-form-input
                    id="vi-tujuan_layanan"
                    v-model="tujuan_layanan"
                    required
                    name="tujuan_layanan"
                    placeholder="Tujuan Layanan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan uraian penjelasan dari fungsi-fungsi yang terdapat dalam layanan.'"
                label="Fungsi Layanan"
                label-for="vi-fungsi_layanan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fungsi Layanan"
                  rules="required"
                >
                  <b-form-input
                    id="vi-fungsi_layanan"
                    v-model="fungsi_layanan"
                    required
                    name="fungsi_layanan"
                    placeholder="Fungsi Layanan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan unit kerja/perangkat daerah yang melaksanakan layanan.'"
                label="Unit Pelaksana"
                label-for="vi-unit_pelaksana"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Unit Pelaksana"
                  rules="required"
                >
                  <b-form-input
                    id="vi-unit_pelaksana"
                    v-model="unit_pelaksana"
                    required
                    name="unit_pelaksana"
                    placeholder="Unit Pelaksana"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan kementerian/lembaga terkait dengan layanan.'"
                label="Pemda / Kementrian / Lembaga terkait"
                label-for="vi-data_instansi_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pemda / Kementrian / Lembaga terkait"
                  rules="required"
                >
                  <v-select
                    id="vi-data_instansi_id"
                    v-model="data_instansi_id"
                    required
                    multiple
                    name="data_instansi_id"
                    :options="referenceData.ref_instansi"
                    placeholder="Pemda / Kementrian / Lembaga terkait"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan target pengguna layanan.'"
                label="Target Layanan"
                label-for="vi-target_layanan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Target Layanan"
                  rules="required"
                >
                  <b-form-input
                    id="vi-target_layanan"
                    v-model="target_layanan"
                    required
                    name="target_layanan"
                    placeholder="Target Layanan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan model dari layanan.'"
                label="Metode Layanan"
                label-for="vi-metode_layanan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Metode Layanan"
                  rules="required"
                >
                  <b-form-textarea
                    id="vi-metode_layanan"
                    v-model="metode_layanan"
                    rows="4"
                    required
                    name="metode_layanan"
                    placeholder="Metode Layanan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              class="mb-1"
            >
              <b-button
                type="submit"
                variant="primary"
              >
                Simpan
              </b-button>
              <b-button
                :to="{ path: '/pengajuan/master/layanan' }"
                variant="outline-secondary"
                class="ml-50"
              >
                Kembali
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-card>
  </section>
  <!--/ profile info  -->

</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BAlert, BButton, BRow, BCol, BCard, BFormGroup, VBTooltip, BFormInput, BFormTextarea,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BButton,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 110 || role < 99) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      required,
      userData: getUserData(),
      referenceData: {
        services_data_list: [],
        ref_area_layanan: [],
        ref_domain_layanan: [],
        ref_kategori_layanan: [],
        ref_sub_kategori_layanan: [],
        ref_instansi: [],
      },
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      service_domain_id: '',
      service_area_id: '',
      service_category: '',
      service_sub_category: '',
      nama_layanan: '',
      tujuan_layanan: '',
      fungsi_layanan: '',
      unit_pelaksana: '',
      target_layanan: '',
      metode_layanan: '',
      data_instansi_id: [],
      daftarMasterKategori: { kategori_layanan_id: 'new', nama: 'Tambah Kategori Layanan Baru' },
      daftarMasterSubKategori: { sub_kategori_layanan_id: 'new', nama: 'Tambah Sub Kategori Layanan Baru' },
      daftarAreaLayanan: [],
      daftarKatLayanan: [],
      daftarSubKatLayanan: [],
      fungsi_and_sub: false,
      sub_fungsi: false,
      kategori_layanan_nama: '',
      sub_kategori_layanan_nama: '',
    }
  },
  mounted() {
    this.getReference()
  },
  methods: {
    getReference() {
      this.$http.get('/clearance/services-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
          }
        })
    },
    getAreaLayanan() {
      this.daftarAreaLayanan.splice(0)
      this.service_area_id = ''

      const filterVal = this.service_domain_id.domain_layanan_id
      const refArea = this.referenceData.ref_area_layanan
      refArea.map(contact => {
        if (contact.domain_layanan_id === filterVal) {
          this.daftarAreaLayanan.push(contact)
        }
        return true
      })
    },
    getKatLayanan() {
      this.daftarKatLayanan = [this.daftarMasterKategori]
      this.fungsi_and_sub = false
      this.sub_fungsi = false
      this.service_category = ''

      const filterVal = this.service_area_id.area_layanan_id
      const refArea = this.referenceData.ref_kategori_layanan
      refArea.map(contact => {
        if (contact.area_layanan_id === filterVal) {
          this.daftarKatLayanan.push(contact)
        }
        return true
      })

      if (this.daftarKatLayanan.length === 1) {
        this.fungsi_and_sub = true
        this.sub_fungsi = true
      }
    },
    getSubKatLayanan() {
      this.daftarSubKatLayanan = [this.daftarMasterSubKategori]
      this.sub_fungsi = false
      this.service_sub_category = ''

      const filterVal = this.service_category.kategori_layanan_id
      const refArea = this.referenceData.ref_sub_kategori_layanan
      refArea.map(contact => {
        if (contact.kategori_layanan_id === filterVal) {
          this.daftarSubKatLayanan.push(contact)
        }
        return true
      })

      if (this.service_category && this.service_category.kategori_layanan_id === 'new') {
        this.fungsi_and_sub = true
      }

      if (this.daftarSubKatLayanan.length === 1) {
        this.sub_fungsi = true
      }
    },
    setSubKatLayanan() {
      if (this.service_sub_category && this.service_sub_category.sub_kategori_layanan_id === 'new') {
        this.sub_fungsi = true
      }
    },
    unitReset(val) {
      if (val === 'cat') {
        this.fungsi_and_sub = false
        this.sub_fungsi = false
        this.kategori_layanan_nama = ''
        this.sub_kategori_layanan_nama = ''
      }
      if (val === 'sub') {
        this.sub_fungsi = false
        this.sub_kategori_layanan_nama = ''
      }
    },
    submitForm() {
      this.$refs.formBisnis.validate().then(success => {
        if (success) {
          this.simpanTemplate()
        }
      })
    },
    simpanTemplate() {
      const listgov = []
      this.data_instansi_id.map(value => listgov.push(value.instansi_id))

      const metaUtama = {
        instansi_id: this.userData.instansi_id,
        domain_layanan_id: this.service_domain_id ? this.service_domain_id.domain_layanan_id : 1,
        area_layanan_id: this.service_area_id ? this.service_area_id.area_layanan_id : 1,
        nama_layanan: this.nama_layanan,
        tujuan_layanan: this.tujuan_layanan,
        fungsi_layanan: this.fungsi_layanan,
        unit_pelaksana: this.unit_pelaksana,
        instansi_terkait: listgov,
        target_layanan: this.target_layanan,
        metode_layanan: this.metode_layanan,
      }
      if (this.service_category && this.service_category.kategori_layanan_id !== 'new') {
        metaUtama.kategori_layanan_id = this.service_category.kategori_layanan_id
      }
      if (this.service_sub_category && this.service_sub_category.sub_kategori_layanan_id !== 'new') {
        metaUtama.sub_kategori_layanan_id = this.service_sub_category.sub_kategori_layanan_id
      }
      if (this.kategori_layanan_nama) {
        metaUtama.kategori_layanan_nama = this.kategori_layanan_nama
      }
      if (this.sub_kategori_layanan_nama) {
        metaUtama.sub_kategori_layanan_nama = this.sub_kategori_layanan_nama
      }

      this.$http.post('/master/master-services/add', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.alert = true
            this.alertDetail = {
              variant: 'success',
              title: 'Data Saved',
              text: res.data.message,
            }

            setTimeout(() => {
              this.alert = false
            }, 2000)
          } else {
            this.alert = true
            this.alertDetail = {
              variant: 'danger',
              title: 'Error Found',
              text: `${res.data.message}: ${res.data.error}`,
              errors: res.data.error,
            }
          }
        })
        .catch(error => {
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.message !== '') ? error.response.data.message : error,
            errors: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
